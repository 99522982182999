<template>
	<div class="pageContainer cbox syssy">
		<div class="tabBox flexBetween">
			<div class="flexStart" style="padding-left:15px;">
				<el-breadcrumb>
					<el-breadcrumb-item>实验室管理</el-breadcrumb-item>
					<el-breadcrumb-item>实验室课程</el-breadcrumb-item>

				</el-breadcrumb>
			</div>
			<div class="tabBar flexEnd" style="margin-right: 10%">
				<div :class="['flexStart tabItem', activeTabIndex == 0 ? 'activeTab' : '']" @click="changeTab(0)">
					<i class="el-icon-notebook-2"></i><span style="margin-left: 5px;">运行表</span>
				</div>
				<div :class="['flexStart tabItem', activeTabIndex == 1 ? 'activeTab' : '']" @click="changeTab(1)">
					<i class="el-icon-notebook-2"></i><span style="margin-left: 5px;">课余时间开放登记表</span>
				</div>
			</div>
		</div>
		<div v-show="activeTabIndex == 0" class="tabContent flexCloumn">
			<el-row class="flexStart" style="flex-wrap: wrap;">
				<!-- <span> 实验室：</span>

                <el-select v-model="sysID" filterable placeholder="请选择实验室" @change="chooseSys" size="small">
                    <el-option v-for="item in sysList" :key="item.id" :label="item.room_name" :value="item.id">
                    </el-option>
                </el-select> -->
				<span> 学期：</span>
				<el-select size="small" v-model="queryForm.term_id" filterable placeholder="学期" style=""
					@change="getSysList">
					<el-option v-for="(item, index) in termList" :key="index" :label="item.title"
						:value="item.id"></el-option>
				</el-select>
        <span  class="ml10">月：</span>
        <el-date-picker
          size="small"
          v-model="queryForm.month"
          value-format="yyyy-MM"
          type="month"
          placeholder="选择月" @change="getSysList">
        </el-date-picker>
<!--				<span class="ml10"> 周次：</span>
				<el-input class="ml10" v-model="queryForm.week" size="small" clearable placeholder="请输入本学期第几周"
					style="width:180px;"></el-input>-->

					

				<!-- <el-date-picker @change="changeDate" size="small" v-model="year_week" :picker-options="{
                    firstDayOfWeek: 1
                }" type="week" :format="showWeek" style="marign-right:50px"></el-date-picker> -->

				<el-button class="ml10" size="small" type="primary" icon="el-icon-search" style="display:inline-block"
					@click="getData">查询</el-button>

				<template v-if="ckSheet.length">
          <el-button :type="canEdit ? 'warning' : 'primary'" size="small"
                     style="margin-left:10px" @click="saveForm">一键保存</el-button>
          <span style="color: #e6a23c;font-size: 14px;font-weight: bold;"><i class="el-icon-warning"></i> 双击单元格进行编辑，编辑完成后点击保存</span>
        </template>
			</el-row>

			<div class="flex_1" style="width: 100%;height: calc(100% - 40px);">
				<div class="flexStretch" style="width: 100%;height: 100%;">
					<div class="dialog_left">
						<div class="bgGrey">

							<div class="flexStartUp" style="width:100%;height:50px;padding-left:15px">
								<el-select v-model="sysID" filterable placeholder="请选择实验室" @change="chooseSys"
									size="small" style="width:calc(100% - 21px );">
									<el-option v-for="item in sysList" :key="item.id" :label="`${item.address}-${item.room_name}`"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="listBox">
								<div :class="['listItem flexStart', activeIndex == index ? 'activeItem' : '']"
									v-for="(item, index) in sysList" @click="() => { getSchoolTimetable(item) }">
									<div class="dotted"></div>
									<div class="title flex_1"> {{ `${item.address}-${item.room_name}` }}</div>
									<i class="icon el-icon-right" v-if="activeIndex == index"></i>
								</div>
							</div>
						</div>
					</div>

					<div class="dialog_right">
						<div class="bcontent">

							<div style=" padding: 20px;width:100%;height: calc(100% - 0px);">

								<div style="text-align: center; padding-bottom: 20px;font-size: 20px;">{{ sysName }}
								</div>

								<table class="dttable" v-for="(row,idx) in ckSheet" :key="idx" style="margin-bottom: 10px;">
									<thead style="width: 100%;">
										<tr style="width: 100%;">
											<td class="colTitle">第{{ row.week }}周</td>
											<td class="colCell">星期一</td>
											<td class="colCell">星期二</td>
											<td class="colCell">星期三</td>
											<td class="colCell">星期四</td>
											<td class="colCell">星期五</td>
                      <td class="colCell">备注</td>
										</tr>
									</thead>
									<tbody style="width: 100%;">
										<tr style="width: 100%;" v-for="(jie,i) in numOfks" :key="`jie${i}`">
											<td class="colTitleR">{{ jie }}</td>
											<td class="colCell" v-for="w in 5" :key="`week${w}`" @dblclick="setCourse(w,i,row)" title="双击编辑">
												<span>{{ row[`week${w}`][i] ? row[`week${w}`][i].course_name : "" }}</span>
											</td>
                      <td v-if="i === 0" rowspan="8" @dblclick="setRemark(row)" style="max-width: 30px;" title="双击编辑">
                        <span>{{ row.remark }}</span>
                      </td>
										</tr>

									</tbody>
								</table>

								<div style="text-align: right; padding: 10px 0;">
									<div class="item" style="display: inline-block; margin-left: 10px;">
										<el-button type="text" size="small">
											<a href='javascript:void(0)' @click="export_syyxb"
												style="text-decoration: none;">{{sysName}}双月运行表</a>
										</el-button> <i class="el-icon-download"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
		<div v-show="activeTabIndex == 1" class="tabContent flexCloumn">
			<el-row class="flexStart" style="flex-wrap: wrap;">
				<span> 学期：</span>
				<el-select size="small" v-model="queryForm2.term_id" filterable placeholder="学期" style=""
					@change="$forceUpdate()">
					<el-option v-for="(item, index) in termList" :key="index" :label="item.title"
						:value="item.id"></el-option>
				</el-select>
				<el-button class="ml10" size="small" type="primary" icon="el-icon-search" style="display:inline-block"
					@click="getAllData">查询</el-button>

        <el-button class="ml10" size="small" type="primary" icon="el-icon-plus" @click="addItem"> 新增</el-button>
			</el-row>

			<div class="flex_1" style="width: 100%;height: calc(100% - 40px);">
        <div class="flexStretch" style="width: 100%; height: 100%">
          <div class="dialog_left">
            <div class="bgGrey">

              <div class="flexStartUp" style="width:100%;height:50px;padding-left:15px">
                <el-select v-model="sysID" filterable placeholder="请选择实验室" @change="chooseSys(2)"
                           size="small" style="width:calc(100% - 21px );">
                  <el-option v-for="item in sysList" :key="item.id" :label="`${item.address}-${item.room_name}`"
                             :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="listBox">
                <div :class="['listItem flexStart', activeIndex == index ? 'activeItem' : '']"
                     v-for="(item, index) in sysList" @click="() => { getSchoolTimetable(item,2) }">
                  <div class="dotted"></div>
                  <div class="title flex_1"> {{ `${item.address}-${item.room_name}` }}</div>
                  <i class="icon el-icon-right" v-if="activeIndex == index"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="dialog_right">
            <div class="bcontent">
              <div class="flexCloumn" style="margin-top: 20px; width: 100%; height: calc(100% - 20px)">
                <div class="flexCenter" style="width: 100%; text-align: center; padding-bottom: 20px;font-size: 20px;">{{
                    sysName }}
                </div>
                <el-table :data="allList" height="100%" stripe border size="small"
                          style="width: fit-content; width: 100%">
                  <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                  <el-table-column prop="open_date" label="日期" width="150" align="center"></el-table-column>
                  <el-table-column prop="class_id" label="开放起止时间" width="200" align="center">
                    <template v-slot="scope">
                      <span>{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="content" label="开放巡视情况" min-width="150" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column label="操作" width="180" fixed="right" align="center">
                    <template slot-scope="scope">
                      <el-tag size="mini" type="danger" style="margin-left: 0px; cursor: pointer"
                              @click="deleteItem(scope.row)"><i class="el-icon-delete"></i>删除</el-tag>

                      <el-tag size="mini" type="success" style="margin-left: 5px; cursor: pointer" @click="editItem(scope.row)"><i class="el-icon-edit"></i>编辑</el-tag>
                    </template>
                  </el-table-column>
                </el-table>

                <el-pagination class="cus_page" :page-sizes="[10, 20, 50, 100]" :page-size="page.per_page" layout="total"
                               :total="page.count" style="margin-top: 10px"> </el-pagination>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>


		<el-dialog title="编辑实验室课程" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog"
			:close-on-click-modal="false">
			<el-form :model="EditItem" label-width="120px" ref="ruleForm">
				<el-row>
					<el-form-item label="学期" prop="title">
						<el-select size="small" disabled v-model="editForm.term_id" filterable placeholder="学期" style=""
							@change="$forceUpdate()">
							<el-option v-for="(item, index) in termList" :key="index" :label="item.title"
								:value="item.id"></el-option>
						</el-select>

					</el-form-item>

          <el-form-item label="当前课时" prop="title">
            <div class=" flexStart"><div>第{{ currKcb.week }}周，星期{{ currKcb.weekDay }}，第{{ currKcb.jie + 1 }}节</div></div>
          </el-form-item>

					<el-form-item label="当前实验室" prop="title">
						<el-select disabled v-model="sysID" filterable placeholder="请选择实验室" style="width:400px">
							<el-option v-for="item in sysList" :key="item.id" :label="item.room_name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
          <el-form-item label="选择课程" prop="course_name">
            <el-select v-model="currKcb.course_name" filterable placeholder="请选择课程" style="width:400px" clearable>
              <el-option v-for="item in courseList" :key="item.id"
                         :label="item.course_name + (item.username ? '(' : '') + (item.username || '') + (item.username ? ')' : '') "
                         :value="item.course_name">
              </el-option>
            </el-select>
          </el-form-item>
				</el-row>


			</el-form>

			<div slot="footer">
				<el-button type="primary" icon="el-icon-check" style="display:inline-block"
					@click="updateCourseTable">保存</el-button>
			</div>
		</el-dialog>
    <el-dialog title="课余时间开放登记"
               :visible.sync="kyDialogVisible"
               custom-class="width_800 cus_dialog" destroy-on-close :close-on-click-modal="false">
      <el-form :model="kydjForm" label-width="120px" ref="kydjForm">
        <el-row>
          <el-form-item label="开放日期" prop="open_date" :rules="[{required:true,message:'请选择日期',trigger:'change'}]">
            <el-date-picker
              v-model="kydjForm.open_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="开放时间" prop="start_time" :rules="[{required: true, validator:validToOpenTime,trigger:'change'}]">
            <el-time-select
              placeholder="起始时间"
              v-model="kydjForm.start_time"
              :picker-options="{
                start: '06:00',
                step: '00:10',
                end: '22:00'
              }" style="margin-right: 10px;">
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-model="kydjForm.end_time"
              :picker-options="{
              start: '06:00',
              step: '00:10',
              end: '22:00',
              minTime: kydjForm.start_time
            }">
            </el-time-select>
          </el-form-item>
          <el-form-item label="开放巡视情况" prop="content" :rules="[{required:true,message:'请输入巡视情况',trigger:'blur'}]">
            <el-input v-model="kydjForm.content" type="textarea" :rows="5"></el-input>
          </el-form-item>
        </el-row>


      </el-form>

      <div slot="footer">
        <el-button type="primary" icon="el-icon-check" style="display:inline-block"
                   @click="saveForm(2)">保存</el-button>
      </div>
    </el-dialog>


		<DeptUser :odata="[EditItem]" v-on:getUser="getUser" v-if="showUserDialog"></DeptUser>

	</div>
</template>

<script>
import DeptUser from '../../com/deptuser.vue'
import { exportWord } from "../../../utils/exportword";
import { ExportBriefDataDocx } from "@/utils/exportBriefDataDocx";
import util from "@/utils/util";
import moment from "moment";

export default {
	components: {
		DeptUser
	},
	data() {
		return {
			temphead: "//school.mmteck.cn/static/temp.png",
			report_year: "",
			week: "",
			year_week: new Date(),
			ckSheet: [],
			EditItem: {
				courses: [],
			},
			keyword:"",
			dialogVisible: false,
			sysID: 0,
			sysName: "",
			sysList: [],
			courseID: "",
			courseList: [],
			TimeName: '',
			canEdit: false,
			ckdItem: {
				idx: -1,
				week: ""
			},
			activeTabIndex: 0,
			activeIndex: 0, //教室
			queryForm: {
        month: this.$moment().format('YYYY-MM')
      },
			queryForm2: {},
			termList: [],
			termIdInit: '',

			value: '',
			defaultWeek: this.getDefaultWeek(),

			allList: [],
			todayOfWeek: this.$moment().format('E'),
			editForm: {},
			showUserDialog: false,
			curr_ks: "",
			numOfks: ['第一节', '第二节', '第三节', '第四节', '第五节', '第六节', '第七节','第八节'],
			currentIdx: "",
			isAdmin: false,
			registerList: [],
			sysInfo: {},
      currKcb: {},
      page: {
        current_page: 1,
        per_page: 20,
        count: 0,
        pageSizes: [10, 50, 100],
      },
      kyDialogVisible: false,
      kydjForm:{
        open_date: moment().format('YYYY-MM-DD'),
        start_time: "",
        end_time: ""
      }
		};
	},
	computed: {
		showWeek() {
			return `${this.report_year} 第 ${this.week} 周`
		},
	},
	mounted() {
		this.getTerm().then(_ => {
			this.getSysList()
		})
		// this.changeDate()
		this.$http.post("/api/get_teacher_info", {

		}).then(res => {
			this.userinfo = res.data
		})

		this.getCourse()
		if (this.$route.query.roomId) {
			this.sysID = Number(this.$route.query.roomId)
		}
    setTimeout(() => {
      if (this.$store.state.teacher && this.$store.state.teacher.sys_roles.length > 0) {
        this.isAdmin = this.$store.state.teacher.sys_roles.filter(r => r.role_code.indexOf('admin') > -1||r.role_code=='sys').length > 0
      }
    },500)
	},
	methods: {
    validToOpenTime(rule, value, callback) {
      if (value == "" || value == undefined || value == null) {
        callback(new Error("请选择开放时间"));
      } else {
        if (!this.kydjForm.start_time) {
          callback(new Error("请选择开始时间"));
        } else if (!this.kydjForm.end_time) {
          callback(new Error("请选择结束时间"));
        } else {
          callback();
        }
      }
    },
		getRegisterList() {
			this.$http.post("/api/sxjd_register_list", {
				pagesize: 90000000,
				term_id: this.queryForm.term_id,
				room_id: this.sysID
			}).then(res => {
				this.registerList = res.data.data;
				// console.log(840,this.registerList)
			})
		},
		getDefaultWeek() {
			const now = new Date();
			const start = new Date(now.getFullYear(), 0, 1);
			const dayOfWeek = start.getDay();
			const adjustedStart = start.getDate() + dayOfWeek * -1;

			const firstThursday = new Date(start.setDate(adjustedStart + 3));
			const firstWeek = new Date(+firstThursday);

			const time = firstWeek.getTime();
			const day = (now.getTime() - time + (now.getTimezoneOffset() * 60000)) % (7 * 24 * 60 * 60 * 1000);

			const currentWeekNumber = Math.floor(day / (7 * 24 * 60 * 60 * 1000)) + 1;

			return firstWeek.getFullYear() + '第' + currentWeekNumber + '周';
		},


		changeTab(idx) {
			this.activeTabIndex = idx
      this.sysID = 0
      this.activeIndex = 0
      this.getSysList()
			// if (idx == 0) {
			//     this.getSysList()
			// } else {
			//     this.getAllData()
			// }
		},

		getTerm() {
			return new Promise(resolve => {
				this.$http.post(`/api/t_sch_terms_list`).then(res => {
					if (res && res.data.data && res.data.data.length > 0) {
						this.termList = res.data.data
						res.data.data && res.data.data.map(a => {
							if (this.globalCurrentDate > a.term_start && this
								.globalCurrentDate < a.term_end) {
								this.termIdInit = a.id
								this.queryForm.term_id = this.termIdInit
								this.queryForm2.term_id = this.termIdInit
							}
						})
						if (!this.termIdInit) {
							this.termIdInit = res.data.data[0].id
							this.queryForm.term_id = this.termIdInit
							this.queryForm2.term_id = this.termIdInit
						}
					}
					this.$forceUpdate()
					resolve()
				})
			})
		},
		getSysList() {
			this.$http.post("/api/syskcb_rooms", {
				pagesize: 2000,
				term_id: this.queryForm.term_id
			}).then(res => {
				this.sysList = res.data
        if (this.sysList.length) {
          if (this.sysID) {
            this.sysName = this.sysList.filter(s => s.id === this.sysID).map(s => s.room_name).pop()
            this.sysInfo = this.sysList.filter(s => s.id === this.sysID)[0];
            this.activeIndex = this.sysList.findIndex(s => s.id === this.sysID)
          } else {
            this.sysID = this.sysList[0].id
            this.sysName = this.sysList[0].room_name
            this.sysInfo = this.sysList[0]
          }
        }
				this.getData()
        this.getAllData()
			})
		},
		getCourse() {
			this.$http.post("/api/t_sch_syskc_list", {
				pagesize: 200
			}).then(res => {
				let courseList = res.data

				this.courseList = this._lo.uniqBy(courseList, item => item.course_id)
				// console.log(863, res.data, this.courseList)
			})
		},
		getData() {
			//获取登记表
			this.registerList = []
			this.$forceUpdate()
			let params = {}
			// if (this.activeTabIndex == 0) {
			params = Object.assign({
				room_id: this.sysID
			}, this.queryForm)
			// } else {
			//     params = {
			//         term_id: this.queryForm.term_id
			//     }
			// }
			//params.term_id = 99;

			this.$http.post("/api/kcb_yxb_list", params).then(res => {
				this.editForm = {
					term_id: this.queryForm.term_id,
					week: this.queryForm.week,
          id: res.data.id || 0,
				}
        this.ckSheet = res.data.yxbList || []
			})
		},
		getAllData() {
			let params = {}
			params = {
				term_id: this.queryForm2.term_id,
        room_id: this.sysID,
        form_type: 2,
			}
			this.$http.post("/api/report_form_list", params).then(res => {

				this.allList =  res.data.data.map(d => {
          return {...d, ...JSON.parse(d.content)}
        })
        this.page.count = res.data.data.length
			})
		},
		chooseSys(type) {
			this.sysList.map((a, ia) => {
				if (this.sysID == a.id) {
					this.sysInfo = a
					this.sysID = a.id
					this.sysName = a.room_name
					this.activeIndex = ia
          if (type === 2) {
            this.getAllData()
          } else {
            this.getData()
          }
					this.jump(ia)
					return
				}
			})

		},
		getSchoolTimetable(row,type) {
			this.sysInfo = row
			this.sysID = row.id
			this.sysName = row.room_name
			this.sysList.map((a, ia) => {
				if (a.id == row.id) {
					this.activeIndex = ia
					return
				}
			})
      if (type === 2) {
        this.getAllData()
      } else {
        this.getData()
      }
		},

		changeDate(e) {

			let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW")
			this.weekStart = this.$moment(this.year_week).utcOffset(480).format("yyyy-MM-DD")
			this.weekEnd = this.$moment(this.year_week).add(6, "day").utcOffset(480).format("yyyy-MM-DD")

			let arr = str.split('-')
			this.report_year = arr[0]
			this.week = arr[1]

			this.getData()

		},
		setCourse(w,i,row) {
      this.dialogVisible = true
      this.currKcb = {
        week: row.week,
        weekDay: w,
        jie: i,
        course_name: row[`week${w}`][i].course_name,
        weekKcb: row
      }
		},
		chooseCourse() {
			let editItem = this.ckSheet[this.ckdItem.idx][this.ckdItem.week]
			if (!this.courseID) {
				this.EditItem.courseID = "";
				this.EditItem.course_name = ""
				this.EditItem.username = ""
				editItem.courseID = "";
				editItem.course_name = "";
				editItem.username = "";
				editItem.courseID = 0;
				editItem.course_id = 0;
				// console.log(editItem)
				// console.log(this.ckSheet[this.ckdItem.idx])

				// this.$http.post("/api/syskcb_save_one", editItem).then(res => {

				//         })

			} else {
				this.EditItem.courseID = this.courseID
				for (let item of this.courseList) {
					if (item.id == this.courseID) {
						this.EditItem.course_name = item.course_name
						this.EditItem.username = item.username
						this.EditItem.courseID = this.courseID
						editItem.course_name = item.course_name
						editItem.username = item.username
						editItem.courseID = this.courseID
						this.ckSheet[this.ckdItem.idx][this.ckdItem.week] = editItem

						if (!this.ckSheet[this.ckdItem.idx][this.ckdItem.week].id) {
							// console.log(this.ckSheet[this.ckdItem.idx])
							// console.log(this.ckdItem.week)

							let formData = {
								year: this.report_year,
								week: this.week,
								sdate: this.weekStart,
								edate: this.weekEnd,
								json_content: this.ckSheet,
								roomid: this.sysID,
								jc: this.ckdItem.idx + 1,
								teacher_id: item.teacher_id,
								teacher_name: item.username,
								weekday: this.ckdItem.week
							}
							// console.log(item)
							formData = {
								...formData,
								...editItem
							}
							// this.$http.post("/api/syskcb_save_one", formData).then(res => {

							// })
							// console.log(1728, formData)
						} else {

							// this.$http.post("/api/syskcb_save_one", editItem).then(res => {

							// })
						}
						break
					}
				}
			}

		},


		getUser(e) {
			console.log('----------------')
			console.log(e)
			if (e && e.length > 0) {
				this.EditItem.teacher_id = e[0].id
				this.EditItem.username = e[0].username
				if (this.currentIdx === 0 || this.currentIdx > 0) {
					this.EditItem.courses[this.currentIdx].teacher_id = e[0].id
					this.EditItem.courses[this.currentIdx].teacher_name = e[0].username
				}
			}
			this.showUserDialog = false
		},
		showDeptUserDialog(idx) {
			//alert(111)
			this.currentIdx = idx
			this.showUserDialog = true
		},
		deleteItem(e) {
			this.$confirm('是否确认删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post('/api/delete_report_forms', { id: e.id }).then(res => {
					this.$message.success('删除成功');
          this.getAllData()
				})
			})
		},
    editItem(row) {
      this.kydjForm = row
      this.kyDialogVisible = true
    },
		saveForm(type) {
      if (type === 2) {
        this.$refs.kydjForm.validate(valid => {
          if (valid) {
            let formData = {
              id: this.kydjForm.id || 0,
              term_id: this.queryForm2.term_id,
              room_id: this.sysID,
              form_type: 2,
              content: JSON.stringify({
                open_date: this.kydjForm.open_date,
                start_time: this.kydjForm.start_time,
                end_time: this.kydjForm.end_time,
                content: this.kydjForm.content,
              })
            }
            this.$http.post('/api/save_report_forms', formData).then(res => {
              this.$message.success('保存成功')
              this.$refs.kydjForm.clearValidate()
              this.kyDialogVisible = false
              this.getAllData()
            })
          }
        })
      } else {
        let formData = {
          id: this.editForm.id,
          term_id: this.queryForm.term_id,
          year_month: this.queryForm.month,
          form_type: 1,
          room_id: this.sysID,
          content: JSON.stringify(this.ckSheet)
        }
        this.$http.post('/api/save_report_forms', formData).then(res => {
          this.$message.success("保存成功")
        })
      }
		},


		jump(index) {

			let target = document.querySelector('.listBox')

			let scrollItems = document.querySelectorAll('.listItem')

			// 判断滚动条是否滚动到底部

			if (target.scrollHeight <= target.scrollTop + target.clientHeight) {

				this.activeStep = index

			}

			let total = scrollItems[index].offsetTop - scrollItems[0]
				.offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)

			let distance = document.querySelector('.listBox').scrollTop // 滚动条距离滚动区域顶部的距离

			// let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)

			// 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次

			// 计算每一小段的距离

			let step = total / 50

			if (total > distance) {

				smoothDown(document.querySelector('.listBox'))

			} else {

				let newTotal = distance - total

				step = newTotal / 50

				smoothUp(document.querySelector('.listBox'))

			}

			// 参数element为滚动区域

			function smoothDown(element) {

				if (distance < total) {

					distance += step

					element.scrollTop = distance

					setTimeout(smoothDown.bind(this, element), 10)

				} else {

					element.scrollTop = total

				}

			}

			// 参数element为滚动区域

			function smoothUp(element) {

				if (distance > total) {

					distance -= step

					element.scrollTop = distance

					setTimeout(smoothUp.bind(this, element), 10)

				} else {

					element.scrollTop = total

				}

			}
		},

		uploadXls() {
			document.getElementById("addXlsbtn").click()
		},

		uploadAttch(e) {
			let _this = this
			this.$http.post("/api/syskcb_import_xls", {
				url: e.src
			}).then(res => {
				let error = res.data.error
				if (error.length) {
					let _html =
						"<div style='max-height: 600px;line-height:28px;text-align:center;overflow-y: auto'>"
					for (let value of error) {
						_html += `<p style="line-height: 30px;">${value}</p>`
					}
					_html += '</div>'
					this.$alert(_html, '导入失败数据', {
						dangerouslyUseHTMLString: true,
					})
				} else {
					this.$message.success("导入成功");
				}
				// this.getData();



			})
		},
		getWeekCourse(kc, jieci) {
			for (let i = 1; i <= 5; i++) {
				let key = `week${i}`
				jieci[key].content = ""
				jieci[key].courses = []
				if (kc[key].courses && kc[key].courses.length > 0) {
					let courses = kc[key].courses
					courses.map(c => {
						c.is_all = !c.weekly;
					})
					let currentCourses = courses.filter(c => c.jie === kc.jie)
					for (let cur of currentCourses) {
						jieci[key].content += `${cur.course_name}.${cur.teacher_name}` + (cur.weekly ? ":" + `${cur.weekly}.` : ".") + cur.class_name + '.'
					}
					jieci[key].content = jieci[key].content.substring(0, jieci[key].content.length - 1)
					jieci[key].courses = courses.filter(c => c.jie === kc.jie)
					jieci[key].prev_next_ids = courses.filter(c => c.jie !== kc.jie).map(c => c.id).join(',')
					jieci[key].kcbIds = courses.filter(c => c.jie === kc.jie).map(c => c.id).join(',')
				}
				if (!jieci[key].courses.length) {
					jieci[key].courses.push({
						term_id: this.termIdInit,
						roomid: this.sysID,
						course_id: "",
						teacher_name: "",
						class_name: "",
						is_all: false,
						weekly: "",
						weekday: i,
						jie: kc.jie
					})
				}
			}
			return jieci
		},
		updateCourseTable() {
			let {weekDay,jie,course_name,weekKcb} = this.currKcb
      weekKcb[`week${weekDay}`][jie].course_name = course_name
      this.dialogVisible = false
      this.saveForm()
		},
		deleteCourseTable() {
			this.$confirm('是否确认删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/syskcb_delete", { ids: this.EditItem.kcbIds }).then(res => {
					this.$message.success("删除成功");
					this.dialogVisible = false
					this.getSysList()
				})
			})
		},
		addItem() {
			this.kyDialogVisible = true
      this.kydjForm = {
        open_date: moment().format('YYYY-MM-DD'),
        start_time: "",
        end_time: ""
      }
		},
		removeItem(idx) {
			if (this.EditItem.courses.length < 2) {
				this.$message.error("至少保留一条数据");
				return;
			}
			this.EditItem.courses.splice(idx, 1)
		},
		async exportWord_register() {
			let index = 0; let list = []; let term_name = ''
			for (let item of this.registerList) {
				index += 1
				item.index = index
				item.class_name = item.class_name || item.class_id
				// item.handsign = item.handsign || ''
				//item.handsign = ''
        if (item.handsign) {
          item.handsign = await util.getBase64Sync(item.handsign)
        }
				list.push(item)
			}
			this.termList.map((a, ia) => {
				console.log(2222, a, this.queryForm.term_id)
				if (a.id == this.queryForm.term_id) {
					term_name = a.title
					return
				}
			})
			let info = this.sysInfo
			info.list = list
			info.term_name = term_name
			info.dept_name = info.dept_name || ''
			setTimeout(() => {
				console.log(2071, info)
        let imgSize = {
          handsign: [45,45],
          centered: false,
        }
				// exportWord('./static/ImportTemps/实训基地使用情况登记表[导出数据模板].doc', info, `${this.sysName}使用情况登记表.doc`)
				ExportBriefDataDocx('/static/ImportTemps/实训基地使用情况登记表[导出数据模板].docx', info, `${this.sysName}使用情况登记表.docx`,imgSize) // text.docx放在了根目录下的public文件夹下

			}, 200);

		},
    setRemark(row) {
      this.$prompt('备注：','添加备注',{
        closeOnClickModal: false,
        inputValue: row.remark
      }).then(({value}) => {
        row.remark = value
        this.saveForm()
      })
    },
    export_syyxb() {
      let param = {...this.queryForm}
      param.room_id = this.sysID
      this.$http.post('/api/export_syyxb', param).then(res => {
        window.location.href = res.data.url
      })
    },
	},
}
</script>
<style lang="less" type="text/less">
.syssy {
	.el-input.is-disabled .el-input__inner {
		color: #606266;
	}
}

.cepContact .el-col {
	display: flex;
	padding: 0;
	margin: 0;
}

.cepContact .el-row {
	width: 100%;
	padding: 0;
	margin: 0;
}
</style>
<style scoped="scoped" lang="less" type="text/less">
.bgLineHeight {
	background-color: rgba(24, 144, 255, 0.08);
}

.cepContact {
	margin-top: 25px;
	width: 100%;
	height: calc(100% - 25px);
	overflow-y: auto;


	.title {
		font-size: 20px;
		line-height: 50px;
	}

	.form {
		width: 100% - 0px;
		border: 1px solid #c0d2ff;
		border-top: 0;

		div {
			font-size: 14px;
			color: #323232;
			box-sizing: border-box;
		}

		.content {
			width: 100%;
			height: 100%;
			padding: 10px;
			white-space: break-spaces;
			word-break: break-all;
			overflow: hidden;
		}

	}

	.col-row {
		width: 100%;
		margin: 0;
		padding: 0;
	}


	.table {
		width: 100%;
	}

	.borderR {
		border-right: 1px solid #c0d2ff;
	}

	.borderB {
		border-bottom: 1px solid #c0d2ff;
	}



	.cell {
		border-left: 1px solid #c0d2ff;
		border-top: 1px solid #c0d2ff;

		min-height: 30px;
		//line-height: 30px;
		//text-align: center;
		white-space: nowrap;
		font-size: 14px;
		word-break: break-all;
		white-space: normal;
	}

	.cell50 {
		min-height: 50px;
		line-height: 50px;
		font-size: 14px;
	}

	.cellMore {
		border-left: 1px solid #c0d2ff;
		border-top: 1px solid #c0d2ff;
		min-height: 50px;
		text-align: left;
		font-size: 14px;
	}


}



.dttable {
	/*table-layout: fixed;*/

	td {
		text-align: center;
		word-wrap: break-word;
		width: auto !important;
	}
  thead td {
    background-color: #98b9fc;
  }

	tbody td {
		/*cursor: pointer;*/
	}

}

.cbox {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 0;

	div {
		box-sizing: border-box;

	}

	.el-table__empty-block {
		width: 100% !important;
	}

	.tabBox {
		width: 100%;
		height: 40px;
		border-bottom: 1px solid #98b9fc;

		.tabBar {
			height: 100%;
			align-items: end;

			.tabItem {
				height: 30px;
				border: 1px solid #3f4bda;
				margin-left: 15px;
				padding: 0 5px;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				margin-bottom: -1px;
				cursor: pointer;
			}

			.activeTab {
				background-color: #2469f3;
				border: 0;
				color: #fff;
			}

			.activeTabTgt {
				background-color: #3e4ddb;
				border: 0;
				color: #fff;
			}
		}
	}

	.tabContent {
		padding: 20px 15px;
		height: calc(100% - 40px);

		.dialog_left {
			width: 300px;
			height: 100%;
			overflow: hidden;

			.bgGrey {
				margin-top: 20px;
				padding: 20px 0;
				height: calc(100% - 20px);
				border-radius: 15px;
				background-color: rgba(24, 144, 255, 0.08);

				.listBox {
					height: 100%;
					padding: 0 15px;
					overflow-y: auto;
					height: calc(100% - 50px);

					.listItem {
						margin-bottom: 20px;
						cursor: pointer;

						.dotted {
							margin-right: 10px;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: #7a7a7a;
						}

						.title {
							color: #7a7a7a;
							font-weight: 700;
						}

						.icon {
							margin-left: 10px;
							color: #3073f7;
							font-weight: 700;
						}
					}

					.activeItem {
						.dotted {
							background-color: #3073f7;
						}

						.title {
							color: #3073f7;
						}
					}
				}
			}
		}

		.dialog_right {
			margin-left: 30px;
			width: calc(100% - 330px);
			height: 100%;
			overflow: hidden;

			.bcontent {
				width: 100%;
				height: 100%;
			}

			.formBox {
				width: 100%;
				height: calc(100% - 50px);
				margin-top: 30px;

				.tabBox {
					width: 100%;
					height: 40px;
					border-bottom: 1px solid #98b9fc;
					align-items: end;

					.tabBar {
						height: 100%;
						align-items: end;
						padding-left: 40px;

						.tabItem {
							height: 30px;
							border: 1px solid #3f4bda;
							margin-right: 15px;
							padding: 0 5px;
							border-top-left-radius: 8px;
							border-top-right-radius: 8px;
							margin-bottom: -1px;
							cursor: pointer;
							font-size: 14px;
							white-space: nowrap;
						}

						.activeTab {
							background-color: #2469f3;
							border: 0;
							color: #fff;
						}

						.activeTabTgt {
							background-color: #3e4ddb;
							border: 0;
							color: #fff;
						}
					}
				}

				.tabContent {
					width: 100%;
					height: calc(100% - 100px);
					padding-left: 40px;
				}
			}

			.targetItem {
				margin-bottom: 10px;
				width: 100%;
			}
		}
	}
}
</style>